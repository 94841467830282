@import "./mixins.scss";

// Login Form
.homePage {
  position: relative;
  top: 200px;

  .user_card {
    height: 400px;
    width: 400px;
    margin-top: auto;
    margin-bottom: auto;
    background: var(--white);
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
  }

  .brand_logo_container {
    position: absolute;
    height: 170px;
    width: 170px;
    top: -75px;
    border-radius: 50%;
    background: #60a3bc;
    padding: 10px;
    text-align: center;
  }

  .loginname {
    margin-top: 20px;
  }

  .loginnames {
    margin-top: 40px;
  }

  .brand_logo {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    border: 2px solid white;
  }

  .form_container {
    margin-top: 50px;

    form {
      width: 80%;

      label {
        color: var(--black);
      }
    }
  }

  .login_btn {
    width: 100%;
    background: var(--black);
    color: var(--white);
  }

  .login_btn:focus {
    box-shadow: none !important;
    outline: 0px !important;
  }

  .input-group-text {
    background: #c0392b !important;
    color: white !important;
    border: 0 !important;
    border-radius: 0.25rem 0 0 0.25rem !important;
  }

  .input_user,
  .input_pass:focus {
    box-shadow: none !important;
    outline: 0px !important;
  }

  .custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #c0392b !important;
  }
}

.sidebarWrapper {
  top: 65px;
  width: 120px;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #fff;
  border-right: 0 solid #e4e4e4;
  z-index: 11;
  box-shadow: 0 .3rem .8rem rgba(0, 0, 0, .12) !important;
  transition: all .2s ease-out;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
  scroll-behavior: smooth;
  overflow-y: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: var(--white);
  }

  &::-webkit-scrollbar {
    width: 5px;
    background-color: var(--white);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--black);
    border: 1px solid var(--text-gray);
  }
}

.metismenu {
  li {
    border-bottom: 1px solid var(--text-gray);

    &:hover {
      background-color: #eae8f16e;
    }

    a {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 15px 10px 15px 10px;

      svg {
        font-size: 25px;
        color: var(--orange);
        margin: auto;
      }

      span {
        display: flex;
        justify-content: center;
        color: var(--black);
        text-align: center;
        font-size: 15px;
      }
    }
  }
}
.formbody{
  padding: unset !important;
}
.topbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 65px;
  background: var(--black);
  border-bottom: 1px solid rgb(228 228 228 / 0%);
  z-index: 10;
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.mobile-toggle-menu {
  width: 202px;

  img {
    width: 100%;
  }
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 55px;
  background: #fff;
  border-bottom: 1px solid rgb(228 228 228 / 0%);
  z-index: 10;
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
  text-align: center;
  display: flex;
  justify-content: center;
  z-index: 11;

  a {
    position: absolute;
    text-decoration: none;
    top: 15px;
    color: var(--black);
  }
}

// End Login Form
.reSendMobileOTP {
  float: right;
  color: var(--black3);
  text-decoration: none;
}

.metismenu {
  margin-bottom: 4rem !important;
}

.user-info {
  display: flex;
  justify-content: end;

  .userimg {
    float: right;
    display: flex;
    justify-content: end;
    width: 40px;
    margin-right: 10px;

    img {
      width: 100%;
      border-radius: 50px;
    }
  }

  .username {
    margin: auto;
    color: var(--orange);
  }
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 3px;
  right: 0px !important;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: unset !important;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 0.25rem;

  li {
    border-bottom: 1px solid #eae8f16e;

    a {
      svg {
        margin-right: 5px;
        color: var(--orange);
        font-size: 20px;
      }
    }
  }
}

.navbar {
  button {
    background: none;
    border: none;
  }
}

.desktopDisplay {
  display: none;

  @include md {
    display: block;
  }
}

.mobileDisplay {
  display: block;

  @include md {
    display: none;
  }
}

.mobileMenuActive {
  display: none !important;
}

.mobileMenuIcones {
  font-size: 30px;
  right: 5px;
  position: relative;
  margin: auto;
  color: var(--white);
}

.user-name {
  color: var(--white);
}

.mobilesidebarWrapper {
  top: 65px;
  width: 202px;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #fff;
  border-right: 0 solid #e4e4e4;
  z-index: 11;
  box-shadow: 0 0.3rem 0.8rem rgba(0, 0, 0, 0.12) !important;
  transition: all 0.2s ease-out;

  .simplebar-mask {
    position: unset;

    .metismenu {
      li {
        padding: 10px 0px 5px 10px !important;
        border-bottom: 1px solid #eae8f16e;

        a {
          flex-direction: unset;
          justify-content: unset;
          gap: 5px;

          svg {
            font-size: 20px;
            margin: unset !important;
          }
        }
      }
    }
  }
}

.page-content {
  padding: 0.2rem 0.2rem 0.2rem 0.2rem;
  position: relative;
  top: 65px;
  @include md{
    padding: 0.7rem 0.7rem 0.7rem 0.7rem;
  }
}

.radius-10 {
  border-radius: 10px;
}

.card {
  border: 0 solid transparent;
  box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 238 / 54%);
  padding: 10px;

  .card-body {
    flex: 1 1 auto;
    padding: 1rem 1rem;
  }
}

.hrpages {
  padding: 10px;

  h4 {
    a {
      float: right;
    }
  }
}

.pagetable {
  border-top: 1px solid var(--text-gray);
}

.btnEdit {
  border: none;
  position: relative;
  margin: 5px;
  color: #fff;
  background: blue;
  border-radius: 5px;
  padding: 2px 5px 2px 5px;
}

.btnDelete {
  border: none;
  position: relative;
  margin: 5px;
  color: #fff;
  background: red;
  border-radius: 5px;
  padding: 2px 5px 2px 5px;
}

.input-group {
  svg {
    margin-top: -5px;
  }
}

.hrstatus {
  cursor: pointer;
}

.spinner-container {
  text-align: center;

  img {
    width: 5%;
  }
}

.formIcons {
  margin-top: -4px;
  font-size: 20px;
  margin-right: 5px;
}

.graphsection {
  padding: 0.7rem 0.5rem 0.1rem 0.7rem;
}

.barChartbody {
  margin: 0px 3px 0px -3px;
  height: unset;

  @include md {
    height: 550px !important;
  }
}
.employeepage {
  .employeepages {
    display: flex;
    justify-content: space-between;
  }
}
.pieChartbody {
  margin: 0px 3px 0px -3px;
}
.yearsection{
  margin-bottom: 50px;
}